import { hasPermision, hasFuncionalidadEmpresa } from './utilidades';

export default {
  hasPermisionListarConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
  hasFuncionalidadConsultaExterna(state) {
    const FUNCIONALIDADS = state.funcionalidadAccions;
    const FUNCIONALIDAD_ID = FUNCIONALIDADS.CONSUL_EXTER_LISTAR.funcionalidad_id;
    const ACCION = FUNCIONALIDADS.CONSUL_EXTER_LISTAR.accion;
    return hasFuncionalidadEmpresa(state, FUNCIONALIDAD_ID, ACCION);
  },
};
